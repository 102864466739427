import Bredcrumb from '../Bredcrumb/Main'
import CateringMenu from "../Allmenus/Thrdmenu/CateringMenu";
import Img from '../../assets/images/background/banner-image-2.jpg'
import React from 'react'

function MenuCatering() {
  return (

    <>
      <Bredcrumb
        subtitle="Our Catering Menu"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
      <CateringMenu />
    </>

  )
}

export default MenuCatering