import React from 'react'
import Offers from '../Allmenus/FirstMenu/Offers'
import Reservation from '../Allmenus/FirstMenu/Reservation'
import Appetizers from '../Allmenus/Thrdmenu/Appetizers'
import Privateevent from '../Allmenus/Thrdmenu/Privateevent'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-2.jpg'
import Specialmenu from "../Allmenus/Menmenu/Specialmenu";

function Menuthree() {
  return (

    <>
      <Bredcrumb
        subtitle="What we offer"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
      <Appetizers />
      <Specialmenu />
      {/*<Privateevent />*/}
      {/*<Offers />*/}
      {/*<Reservation />*/}

    </>

  )
}

export default Menuthree