import React from 'react'
// import { Link } from 'react-router-dom'
// import Iframe from 'react-iframe'
import {Box, Modal, Typography} from "@mui/material";
import bg25 from '../../assets/images/background/bg-1.png'
import bg6 from '../../assets/images/background/bg-6.png'
import restro from '../../assets/images/resource/contact.jpg'

const encode = (data) => {
    return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&")
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgb(228,197,144)',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};


function Contactbanner() {
    // React useState
    const [formValues, setFormValues] = React.useState({name: '', email: '', phoneNumber: '', message: ''});
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        await fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "contact-form", ...formValues })
        });

        setFormValues({name: '', email: '', phoneNumber: '', message: ''})
        handleOpen(true);
    }

    return (

        <>
            {/*< A little help for the Netlify post-processing bots >*/}
            <form name="contact-form" data-netlify="true" netlify-honeypot="bot-field" hidden>
                <input type="text" name="name" />
                <input type="text" name="email" />
                <input type="text" name="phoneNumber" />
                <textarea name="message"></textarea>
            </form>

            {/*<div className="contact-map">*/}
            {/*    <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81392.11276571127!2d-74.03116502768826!3d40.70144849986174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667928043843!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>*/}
            {/*</div>*/}

            <section className="contact-page">
                <div className="left-bg"><img src={bg25} alt="" title="" /></div>
                <div className="right-bg"><img src={bg6} alt="" title="" /></div>

                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    {/*<div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">*/}
                                    {/*    <h4>Lunch Time</h4>*/}
                                    {/*    <div className="text"> Monday to Sunday <br /> 11.00 am - 2.30pm</div>*/}
                                    {/*</div>*/}
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Contact Info</h4>
                                        <div className="text">Kriti Maharjan</div>
                                        <div className="text">Mickleham, Melbourne<br />Email : Kmaharjan@lumanti.com.au</div>
                                        <div className="more-link"><a href="tel:0421445112">☎ : 0421445112</a></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    {/*<div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">*/}
                                    {/*    <h4>Dinner Time</h4>*/}
                                    {/*    <div className="text">Monday to Sunday<br />5.30 pm - 11.30 pm</div>*/}
                                    {/*</div>*/}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>Message us</h2>
                                    <div className="text desc">Have a question about the our service? We're here to help, contact us today</div>
                                </div>
                                <div className="default-form reservation-form">

                                    <form onSubmit={onSubmit}>
                                        <div className="clearfix">
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input type="text" name="name" placeholder="Your Name" onChange={(e) => setFormValues({...formValues, [e.target.name]:e.target.value})} required="" value={formValues.name} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input type="text" name="email" placeholder="Your Email" onChange={(e) => setFormValues({...formValues, [e.target.name]:e.target.value})} required="" value={formValues.email} />
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <input type="text" name="phoneNumber" placeholder="Phone Number" onChange={(e) => setFormValues({...formValues, [e.target.name]:e.target.value})} required="" value={formValues.phoneNumber} />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <textarea name="message" placeholder="Special Request"  onChange={(e) => setFormValues({...formValues, [e.target.name]:e.target.value})} required="" value={formValues.message}></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="theme-btn btn-style-one clearfix">
                                                    <span className="btn-wrap">
                                                        <span className="text-one">send your message</span>
                                                        <span className="text-two">send your message</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <img src={restro} alt="" />
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Thank you for your message!!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                        We'll get back to you shortly.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                        <button className="theme-btn btn-style-one clearfix" onClick={handleClose}>
                            <span className="btn-wrap">
                                <span className="text-one">close</span>
                                <span className="text-two">close</span>
                            </span>
                        </button>
                    </Typography>
                </Box>
            </Modal>

        </>
    )
}

export default Contactbanner