import React from 'react'
import './catering-menu.css'
import {Link} from "react-router-dom";

function CateringMenu() {
  return (
    <section className="lumanti-mel-catering-menu">
      <div className="lumanti-mel-catering-menu-container">
        <h2 className="lumanti-mel-catering-menu-heading">Starters to wow your guest: <span className="lumanti-mel-catering-menu-price">$12</span></h2>
        <div className="lumanti-mel-catering-menu-options-container">
          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Option 1</h3>
              <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Chicken Choila</h5>
              <h5>Hariyo Bhatmas Sadheko</h5>
              <h5>Piro Aalu</h5>
              <h5>Pakora</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Option 2</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Veg. Chowmein</h5>
              <h5>Chilli Chicken</h5>
              <h5>Piro Aalu</h5>
              <h5>Veg Spring Rolls</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Option 3</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Chicken Choila</h5>
              <h5>Crispy Bhatmas</h5>
              <h5>Mini Bara</h5>
              <h5>Mini Yomari</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Option 4</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Pork Chilli</h5>
              <h5>Mini Bara</h5>
              <h5>Samosas</h5>
              <h5>Piro Aalu</h5>
            </div>
          </div>

        </div>
      </div>

      <div className="lumanti-mel-catering-menu-container">
        <h2 className="lumanti-mel-catering-menu-heading">Big Plates</h2>
        <div className="lumanti-mel-catering-menu-options-container">
          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Puja set - $18</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Kheer</h5>
              <h5>Poori</h5>
              <h5>Matar Paneer</h5>
              <h5>Mix Achaar</h5>
              <h5>Chanaa Gravy</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Simple set - $20 - $22</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Jeera Rice</h5>
              <h5>Aalu Taama</h5>
              <h5>Khasi / Kukhura ko Tarkari</h5>
              <h5>Masala Paneer</h5>
              <h5>Nutrella ko Achaar</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Newari set - $20</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Chiura</h5>
              <h5>Usineko Andaa</h5>
              <h5>Choila (Chicken / Lamb)</h5>
              <h5>Bodi</h5>
              <h5>Aalu Taama</h5>
              <h5>Bhatmas</h5>
              <h5>Mixed Achaar</h5>
              <h5>Baara</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Nepali Set - $20 - $22</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Pulau</h5>
              <h5>Kukhura/Khasi ko Tarkari</h5>
              <h5>Rajma or Dal Makhani</h5>
              <h5>Mausami Tarkari</h5>
              <h5>Amilo Piro Mixed Achaar</h5>
            </div>
          </div>

        </div>
      </div>

      <div className="lumanti-mel-catering-menu-container">
        <div className="lumanti-mel-catering-menu-options-container">
          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Thapua (Per Kilo)</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div className="lumanti-mel-catering-menu-additional">
              <h5>Chilli Chicken</h5>
              <h5>$38</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Fish Fry</h5>
              <h5>$30</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Roast Chicken</h5>
              <h5>$25</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Khasi / Pork ko Tarkari</h5>
              <h5>$40</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Chicken Choila</h5>
              <h5>$40</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Lamb / Buff Choila</h5>
              <h5>$50</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Mushroom Choila</h5>
              <h5>$40</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Pork Chilli</h5>
              <h5>$40</h5>
            </div>

            <div className="lumanti-mel-catering-menu-additional">
              <h5>Pork and crispy Bhatmas Sekuwa</h5>
              <h5>$45</h5>
            </div>
          </div>

          <div className="lumanti-mel-catering-menu-option">
            <h3 className="lumanti-mel-catering-menu-option-heading">Kids - $9</h3>
            <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            <div>
              <h5>Chicken MoMo</h5>
              <h5>Chicken Nuggets</h5>
              <h5>Wedges</h5>
              <img className="lumanti-mel-catering-menu-separater" src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
              <h5>Veg. Chowmein</h5>
              <h5>Chicken Wings</h5>
              <h5>Spring Rolls</h5>
            </div>
          </div>

        </div>

        <div className="lumanti-mel-catering-menu-disclouser">
          <h6 className="lumanti-mel-catering-menu-disclouser-text">😀 Please be advise, we do not provide crockery.</h6>
          <h6 className="lumanti-mel-catering-menu-disclouser-text">😀 Do not hesitate to give me a call, if you would like to hire chafing dish.</h6>
          <h6 className="lumanti-mel-catering-menu-disclouser-text">😀 Free Delivery within 5 km, Delivery charges may apply.</h6>
        </div>
      </div>

      <div className="lumanti-mel-catering-menu-container">
        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
          <div className="link">
            <Link to="/menu" className="theme-btn btn-style-two clearfix">
            <span className="btn-wrap">
              <span className="text-one">view our menu</span>
              <span className="text-two">view our menu</span>
            </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CateringMenu