import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedNumber from "animated-number-react"
import Iframe from 'react-iframe';
import bgten from '../../assets/images/background/bg-10.png'
import bgeleven from '../../assets/images/background/bg-11.png'
import signatureone from '../../assets/images/resource/signature-1.png'
import aboutimgone from '../../assets/images/resource/about-image-2.jpg'
import aboutUs from '../../assets/images/resource/about-us.jpg'
import aboutimgtwo from '../../assets/images/resource/about-image-1.jpg'

function Aboutsec() {

    const [Video, setVideo] = useState()

    return (
        <>
            <section className="about-section">
                <div className="left-bg"><img src={bgten} alt="" title="" /></div>
                <div className="right-bg"><img src={bgeleven} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>who we are</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h3>At Lumanti, we bring the vibrant and flavorful cuisine of Nepal to your table. <span className="theme_color">Our goal</span> is to provide you with an authentic food that showcases the richness of Nepali culture.</h3>
                    </div>
                    <div className="row clearfix">

                        <div className="about-block content-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="text">Our menu features a variety of dishes that are inspired by the traditional flavors and cooking techniques of Nepal. From momos (dumplings) to dal bhat (lentil soup and rice), our dishes are carefully crafted using fresh and locally-sourced ingredients. Our chefs are trained to ensure that every dish is prepared with the utmost care and attention to detail, so that you can savor the true essence of Nepali/Newari cuisine.
                                    In addition to our delicious food, we strive to create authentic taste that transports you to the heart of Nepal.
                                    We believe that food is not just about nourishment, but also about bringing people together. Whether you are with friends, family, or colleagues, our service is perfect to enjoy a delicious meal and create unforgettable taste.
                                    Thank you for choosing Lumanti for your Nepali/Newari food experience. We look forward to serving you soon!</div>
                                <div className="author">Kriti Maharjan - founder</div>
                            </div>
                        </div>


                        <div className="about-block image-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="image"><img src={aboutUs} alt="" /></div>
                            </div>
                        </div>


                        {/*<div className="about-block info-block col-xl-4 col-lg-4 col-md-12 col-sm-12">*/}
                        {/*    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">*/}
                        {/*        <ul className="info">*/}
                        {/*            <li><h3>Catering</h3></li>*/}
                        {/*            <li><strong>We do it all!</strong>Birthday Parties, Graduations, Anniversaris, Weddings <br /> and many more...</li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </section>

        </>
    )
}

export default Aboutsec